@import url('https://fonts.googleapis.com/css?family=Kanit:700|Raleway:400,900&display=swap');

body {
    margin: 0;
}

.footer_section {
    height: 200px;
    /* background-color: rgb(4, 36, 46); */
    background-image: linear-gradient(to right, rgb(26, 102, 165) , rgb(5, 4, 46));
}

.footer_title {
    font-family: 'Kanit', sans-serif;
    font-size: 64px;
    color: white;
    text-align: center;
}

.footer_content {    
    font-family: 'Raleway', sans-serif;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-self: center;
    align-content: center;
    font-size: 14px;
    color: grey;
    text-align: center;
    height: 100%;
    max-height: 100px;
    width: 100%;
    max-width: 300px;
    border-left: 1px solid grey;
    border-right: 1px solid grey;
    margin: 0 auto;
}

.back_to_top {    
    text-align: center;    
    padding-top: 10px;  
}

.back_to_top {
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    color: peru;
    text-decoration: underline;
    padding-top: 24px;
}