@import url(https://fonts.googleapis.com/css?family=Montserrat:300&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300&display=swap);
@import url(https://fonts.googleapis.com/css?family=Abril+Fatface&display=swap);
@import url(https://fonts.googleapis.com/css?family=Abril+Fatface&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300&display=swap);
@import url(https://fonts.googleapis.com/css?family=Abril+Fatface&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300&display=swap);
@import url(https://fonts.googleapis.com/css?family=Abril+Fatface&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300&display=swap);
@import url(https://fonts.googleapis.com/css?family=Abril+Fatface&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300&display=swap);
@import url(https://fonts.googleapis.com/css?family=Abril+Fatface&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300&display=swap);
@import url(https://fonts.googleapis.com/css?family=Abril+Fatface&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300&display=swap);
@import url(https://fonts.googleapis.com/css?family=Abril+Fatface&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300&display=swap);
@import url(https://fonts.googleapis.com/css?family=Abril+Fatface&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300&display=swap);
@import url(https://fonts.googleapis.com/css?family=Kanit:700|Raleway:400,900&display=swap);

/* font-family: 'Montserrat', sans-serif; */

.nav-bar {
    position: fixed;
    top: 0;
    left: 0;
    text-align: right;
    width: 100%;
    /* background-color: rgb(4, 36, 46); */
    background-image: -webkit-gradient(linear, left top, right top, from(rgb(26, 102, 165)) , to(rgb(5, 4, 46)));
    background-image: linear-gradient(to right, rgb(26, 102, 165) , rgb(5, 4, 46));
    color: white;
    padding-top: 8px;
    padding-right: 40px;
    padding-bottom: 6px;
    height: 26px;
    z-index: 1;
}

.nav-links {
    font-family: 'Montserrat', sans-serif;
    list-style: none;
    padding: 5px 10px 10px 10px;
}    

.nav-bar > .active {
    font-family: 'Montserrat', sans-serif;
    color: peru;
    border-bottom: 2px solid white;    
}

.nav_logo {
    width: 160px;
    float: left;
    padding-left: 20px;
}

@media screen and (max-width: 990px) {
    .nav-links {
        display: none;
    }

    .nav_logo {
        width: 130px;
        float: left;
        padding-left: 20px;
    }
}
/* font-family: 'Montserrat', sans-serif; */
/* font-family: 'Abril Fatface', cursive; */



body {
    margin: 0;
}

.main_page_section {
    display: flex;
    align-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-self: center;
    align-content: stretch;
    margin-top: 40px;
    background-color: black;
    color: white;
    height: 800px;
    width: 100%;
}

.main_section_background {
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.main_content_container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-self: center;
    align-content: center;
    height: 100%;
    max-height: 400px;
    width: 100%;
    max-width: 1000px;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, .4);
    margin: 10% auto;
}

.main_headline {
    font-family: 'Abril Fatface', cursive;
    font-size: 96px;
    color: white;
    text-align: center;
}

.main_paragraph_01 {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    color: white;
    margin: 24px;
}




/* -------------------- LARGE TABLET ------------- */
@media screen and (max-width: 1200px) and (min-width: 992px) {
    .main_headline {
        font-family: 'Abril Fatface', cursive;
        font-size: 80px;
        color: white;
    }
    
    .main_paragraph_01 {
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        color: white;
        margin: 24px;
    }
}
/* -------------------- SMALL TABLET ------------- */
@media screen and (max-width: 991px) and (min-width: 768px){    
    .main_headline {
        font-family: 'Abril Fatface', cursive;
        font-size: 64px;
        color: white;
    }
    
    .main_paragraph_01 {
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        color: white;
        margin: 24px;
    }  
}
/* -------------------- LARGE PHONE ------------- */
@media screen and (max-width: 767px) and (min-width: 576px){
    .main_headline {
        font-family: 'Abril Fatface', cursive;
        font-size: 48px;
        color: white;
    }
    
    .main_paragraph_01 {
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        color: white;
        margin: 24px;
    }     
}
/* -------------------- SMALL PHONE ------------- */
@media screen and (max-width: 575px) and (min-width: 319px){  
    .main_headline {
        font-family: 'Abril Fatface', cursive;
        font-size: 36px;
        color: white;
    }
    
    .main_paragraph_01 {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        color: white;
        margin: 24px;
    }   
}



/* font-family: 'Abril Fatface', cursive; */
/* font-family: 'Montserrat', sans-serif; */

body {
    margin: 0;
}

.phil_page_section {
    display: flex;
    align-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-self: center;
    align-content: stretch;
    /* background-image: linear-gradient(to right, rgb(9, 44, 71) , rgb(65, 64, 94)); */
    background-color: white;
    color: white;
    height: 1660px;
    width: 100%;
}

.phil_section_background {
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.phil_content_container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-self: center;
    align-content: center;
    height: 100%;
    max-height: 1400px;
    width: 100%;
    max-width: 1000px;
    /* border-radius: 8px; */
    margin: 10% auto;
    border: white solid 1px;
    background-color: rgb(113, 161, 180);
}

.phil_headline {
    font-family: 'Abril Fatface', cursive;
    font-size: 96px;
    color: white;
}

.phil_paragraph_section {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    color: white;
    margin: 24px;
}

.phil_paragraph_box {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    color: rgb(35, 35, 35);
    margin: 24px;
}

.phil_columns_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    align-content: flex-start;
    margin: 24px 24px 120px;
    max-width: 1200px;
}

.phil_column_box {
    width: 376px;
	background-color: rgb(255, 255, 255);
	border-radius: 9px;
	padding-bottom: 12px;
	margin: 24px;	
}

.phil_box_image {
    width: 100%;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
}

.phil_column_box_title {
    font-family: 'Abril Fatface', cursive;
    font-size: 24px;
    color: peru;
    padding: 24px 24px 12px;
}



/* -------------------- LARGE TABLET ------------- */
@media screen and (max-width: 1200px) and (min-width: 992px) {
    .phil_headline {
        font-family: 'Abril Fatface', cursive;
        font-size: 80px;
        color: white;
    }
    
    .phil_paragraph_section {
        font-family: 'Montserrat', sans-serif;
        font-size: 18px;
        color: white;
        margin: 24px;
    }
}
/* -------------------- SMALL TABLET ------------- */
@media screen and (max-width: 991px) and (min-width: 768px){    
    .phil_headline {
        font-family: 'Abril Fatface', cursive;
        font-size: 64px;
        color: white;
    }
    
    .phil_paragraph_section {
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        color: white;
        margin: 24px;
    }
}
/* -------------------- LARGE PHONE ------------- */
@media screen and (max-width: 767px) and (min-width: 576px){
    .phil_headline {
        font-family: 'Abril Fatface', cursive;
        font-size: 48px;
        color: white;
    }
    
    .phil_paragraph_section {
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        color: white;
        margin: 24px;
    }   
}
/* -------------------- SMALL PHONE ------------- */
@media screen and (max-width: 575px) and (min-width: 319px){  
    .phil_headline {
        font-family: 'Abril Fatface', cursive;
        font-size: 36px;
        color: white;
    }
    
    .phil_paragraph_section {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        color: white;
        margin: 24px;
    }
}



/* font-family: 'Abril Fatface', cursive; */
/* font-family: 'Montserrat', sans-serif; */

body {
    margin: 0;
}

.recog_page_section {
    display: flex;
    align-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-self: center;
    align-content: stretch;
    background-color: rgb(28, 185, 67);
    color: white;
    height: 800px;
    width: 100%;
}

.recog_section_background {
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.recog_content_container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-self: center;
    align-content: center;
    height: 100%;
    max-height: 400px;
    width: 100%;
    max-width: 1000px;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, .4);
    margin: 10% auto;
}

.recog_headline {
    font-family: 'Abril Fatface', cursive;
    font-size: 96px;
    color: white;
}

.recog_paragraph_01 {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    color: white;
    margin: 24px;
}



/* -------------------- LARGE TABLET ------------- */
@media screen and (max-width: 1200px) and (min-width: 992px) {
    .recog_headline {
        font-family: 'Abril Fatface', cursive;
        font-size: 80px;
        color: white;
    }
    
    .recog_paragraph_01 {
        font-family: 'Montserrat', sans-serif;
        font-size: 18px;
        color: white;
        margin: 24px;
    }
}
/* -------------------- SMALL TABLET ------------- */
@media screen and (max-width: 991px) and (min-width: 768px){    
    .recog_headline {
        font-family: 'Abril Fatface', cursive;
        font-size: 64px;
        color: white;
    }
    
    .recog_paragraph_01 {
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        color: white;
        margin: 24px;
    }
}
/* -------------------- LARGE PHONE ------------- */
@media screen and (max-width: 767px) and (min-width: 576px){
    .recog_headline {
        font-family: 'Abril Fatface', cursive;
        font-size: 48px;
        color: white;
    }
    
    .recog_paragraph_01 {
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        color: white;
        margin: 24px;
    }  
}
/* -------------------- SMALL PHONE ------------- */
@media screen and (max-width: 575px) and (min-width: 319px){  
    .recog_headline {
        font-family: 'Abril Fatface', cursive;
        font-size: 36px;
        color: white;
    }
    
    .recog_paragraph_01 {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        color: white;
        margin: 24px;
    }
}



/* font-family: 'Abril Fatface', cursive; */
/* font-family: 'Montserrat', sans-serif; */

body {
    margin: 0;
}

.youtube1_page_section {
    display: flex;
    align-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-self: center;
    align-content: stretch;
    background-color: rgb(113, 161, 180);
    color: black;
    height: 100%;
    width: 100%;
}

.youtube_section_background {
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    /* padding: 24px */
}

.youtube_content_container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-self: center;
    align-content: center;
    border: 1px white solid;
    border-radius: 8px;
    height: 100%;
    width: 100%;    
    background-color: rgb(113, 161, 180);
    margin: 5% 10%;    
}

.youtube_headline {
    font-family: 'Abril Fatface';
    font-size: 80px;
    color: white;
}

.youtube_player {
    width: 768px;
    height: 432px;
    margin: 20px auto;
}







/* -------------------- LARGE TABLET ------------- */
@media screen and (max-width: 1200px) and (min-width: 992px) {
    .youtube_headline {
        font-family: 'Abril Fatface', cursive;
        font-size: 80px;
        color: white;
    }
    
    .youtube_player {
        width: 800px;
        height: 700px;
    }
}
/* -------------------- SMALL TABLET ------------- */
@media screen and (max-width: 991px) and (min-width: 768px){    
    .youtube_headline {
        font-family: 'Abril Fatface', cursive;
        font-size: 64px;
        color: white;
    }
    
    .youtube_player {
        width: 640px;
        height: 540px;
    }
}
/* -------------------- LARGE PHONE ------------- */
@media screen and (max-width: 767px) and (min-width: 576px){
    .youtube_headline {
        font-family: 'Abril Fatface', cursive;
        font-size: 48px;
        color: white;
    }

    .youtube_player {
        width: 480px;
        height: 380px
    }
}
/* -------------------- SMALL PHONE ------------- */
@media screen and (max-width: 575px) and (min-width: 319px){  
    .youtube_headline {
        font-family: 'Abril Fatface', cursive;
        font-size: 36px;
        color: white;
    }

    .youtube_player {
        width: 320px;
        height: 320px;
    }
}



/* font-family: 'Abril Fatface', cursive; */
/* font-family: 'Montserrat', sans-serif; */

body {
    margin: 0;
}

.travels_page_section {
    display: flex;
    align-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-self: center;
    align-content: stretch;
    background-color: rgb(76, 39, 212);
    color: white;
    height: 800px;
    width: 100%;
}

.travels_section_background {
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.travels_content_container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-self: center;
    align-content: center;
    height: 100%;
    max-height: 400px;
    width: 100%;
    max-width: 1000px;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, .4);
    margin: 10% auto;
}

.travels_headline {
    font-family: 'Abril Fatface', cursive;
    font-size: 96px;
    color: white;
}

.travels_paragraph_01 {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    color: white;
    margin: 24px;
}





/* -------------------- LARGE TABLET ------------- */
@media screen and (max-width: 1200px) and (min-width: 992px) {
    .travels_headline {
        font-family: 'Abril Fatface', cursive;
        font-size: 80px;
        color: white;
    }
    
    .travels_paragraph_01 {
        font-family: 'Montserrat', sans-serif;
        font-size: 18px;
        color: white;
        margin: 24px;
    }
}
/* -------------------- SMALL TABLET ------------- */
@media screen and (max-width: 991px) and (min-width: 768px){    
    .travels_headline {
        font-family: 'Abril Fatface', cursive;
        font-size: 64px;
        color: white;
    }
    
    .travels_paragraph_01 {
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        color: white;
        margin: 24px;
    }
}
/* -------------------- LARGE PHONE ------------- */
@media screen and (max-width: 767px) and (min-width: 576px){
    .travels_headline {
        font-family: 'Abril Fatface', cursive;
        font-size: 48px;
        color: white;
    }
    
    .travels_paragraph_01 {
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        color: white;
        margin: 24px;
    }  
}
/* -------------------- SMALL PHONE ------------- */
@media screen and (max-width: 575px) and (min-width: 319px){  
    .travels_headline {
        font-family: 'Abril Fatface', cursive;
        font-size: 36px;
        color: white;
    }
    
    .travels_paragraph_01 {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        color: white;
        margin: 24px;
    }
}



/* font-family: 'Abril Fatface', cursive; */
/* font-family: 'Montserrat', sans-serif; */



body {
    margin: 0;
}

.running_page_section {
    display: flex;
    align-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-self: center;
    align-content: stretch;
    background-color: rgb(202, 173, 44);
    color: white;
    height: 800px;
    width: 100%;
}

.running_section_background {
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.running_content_container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-self: center;
    align-content: center;
    height: 100%;
    /* max-height: 400px; */
    width: 100%;
    /* max-width: 1000px; */
    border-radius: 8px;
    background-color: rgba(8, 50, 66, 0.8);
    /* margin: 10% auto; */
}

.running_headline {
    font-family: 'Abril Fatface', cursive;
    font-size: 96px;
    color: white;
}

.running_paragraph_01 {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    color: white;
    margin: 24px;
}



/* -------------------- LARGE TABLET ------------- */
@media screen and (max-width: 1200px) and (min-width: 992px) {
    .running_headline {
        font-family: 'Abril Fatface', cursive;
        font-size: 80px;
        color: white;
    }
    
    .running_paragraph_01 {
        font-family: 'Montserrat', sans-serif;
        font-size: 18px;
        color: white;
        margin: 24px;
    }
}
/* -------------------- SMALL TABLET ------------- */
@media screen and (max-width: 991px) and (min-width: 768px){    
    .running_headline {
        font-family: 'Abril Fatface', cursive;
        font-size: 64px;
        color: white;
    }
    
    .running_paragraph_01 {
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        color: white;
        margin: 24px;
    }
}
/* -------------------- LARGE PHONE ------------- */
@media screen and (max-width: 767px) and (min-width: 576px){
    .running_headline {
        font-family: 'Abril Fatface', cursive;
        font-size: 48px;
        color: white;
    }
    
    .running_paragraph_01 {
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        color: white;
        margin: 24px;
    }  
}
/* -------------------- SMALL PHONE ------------- */
@media screen and (max-width: 575px) and (min-width: 319px){  
    .running_headline {
        font-family: 'Abril Fatface', cursive;
        font-size: 36px;
        color: white;
    }
    
    .running_paragraph_01 {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        color: white;
        margin: 24px;
    }
}



/* font-family: 'Abril Fatface', cursive; */
/* font-family: 'Montserrat', sans-serif; */

body {
    margin: 0;
}

.photos_page_section {
    display: flex;
    align-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-self: center;
    align-content: stretch;
    background-color: rgb(110, 22, 63);
    color: white;
    height: 800px;
    width: 100%;
}

.photos_section_background {
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.photos_content_container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-self: center;
    align-content: center;
    height: 100%;
    max-height: 400px;
    width: 100%;
    max-width: 1000px;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, .4);
    margin: 10% auto;
}

.photos_headline {
    font-family: 'Abril Fatface', cursive;
    font-size: 96px;
    color: white;
}

.photos_paragraph_01 {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    color: white;
    margin: 24px;
}





/* -------------------- LARGE TABLET ------------- */
@media screen and (max-width: 1200px) and (min-width: 992px) {
    .photos_headline {
        font-family: 'Abril Fatface', cursive;
        font-size: 80px;
        color: white;
    }
    
    .photos_paragraph_01 {
        font-family: 'Montserrat', sans-serif;
        font-size: 18px;
        color: white;
        margin: 24px;
    }
}
/* -------------------- SMALL TABLET ------------- */
@media screen and (max-width: 991px) and (min-width: 768px){    
    .photos_headline {
        font-family: 'Abril Fatface', cursive;
        font-size: 64px;
        color: white;
    }
    
    .photos_paragraph_01 {
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        color: white;
        margin: 24px;
    }
}
/* -------------------- LARGE PHONE ------------- */
@media screen and (max-width: 767px) and (min-width: 576px){
    .photos_headline {
        font-family: 'Abril Fatface', cursive;
        font-size: 48px;
        color: white;
    }
    
    .photos_paragraph_01 {
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        color: white;
        margin: 24px;
    }  
}
/* -------------------- SMALL PHONE ------------- */
@media screen and (max-width: 575px) and (min-width: 319px){  
    .photos_headline {
        font-family: 'Abril Fatface', cursive;
        font-size: 36px;
        color: white;
    }
    
    .photos_paragraph_01 {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        color: white;
        margin: 24px;
    }
}



/* font-family: 'Abril Fatface', cursive; */
/* font-family: 'Montserrat', sans-serif; */

body {
    margin: 0;
}

.about_page_section {
    display: flex;
    align-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-self: center;
    align-content: stretch;
    background-color: rgb(134, 196, 221);
    color: white;
    height: 800px;
    width: 100%;
}

.about_section_background {
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.about_content_container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-self: center;
    align-content: center;
    height: 100%;
    /* max-height: 400px; */
    width: 100%;
    /* max-width: 1000px; */
    border-radius: 8px;
    background-color: rgba(8, 50, 66, 0.8);
    /* margin: 10% auto; */
}

.about_headline {
    font-family: 'Abril Fatface', cursive;
    font-size: 96px;
    color: white;
}

.about_paragraph_01 {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    color: white;
    margin: 24px;
}





/* -------------------- LARGE TABLET ------------- */
@media screen and (max-width: 1200px) and (min-width: 992px) {
    .about_headline {
        font-family: 'Abril Fatface', cursive;
        font-size: 80px;
        color: white;
    }
    
    .about_paragraph_01 {
        font-family: 'Montserrat', sans-serif;
        font-size: 18px;
        color: white;
        margin: 24px;
    }
}
/* -------------------- SMALL TABLET ------------- */
@media screen and (max-width: 991px) and (min-width: 768px){    
    .about_headline {
        font-family: 'Abril Fatface', cursive;
        font-size: 64px;
        color: white;
    }
    
    .about_paragraph_01 {
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        color: white;
        margin: 24px;
    }
}
/* -------------------- LARGE PHONE ------------- */
@media screen and (max-width: 767px) and (min-width: 576px){
    .about_headline {
        font-family: 'Abril Fatface', cursive;
        font-size: 48px;
        color: white;
    }
    
    .about_paragraph_01 {
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        color: white;
        margin: 24px;
    }  
}
/* -------------------- SMALL PHONE ------------- */
@media screen and (max-width: 575px) and (min-width: 319px){  
    .about_headline {
        font-family: 'Abril Fatface', cursive;
        font-size: 36px;
        color: white;
    }
    
    .about_paragraph_01 {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        color: white;
        margin: 24px;
    }
}



/* font-family: 'Abril Fatface', cursive; */
/* font-family: 'Montserrat', sans-serif; */

body {
    margin: 0;
}

.contact_page_section {
    display: flex;
    align-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-self: center;
    align-content: stretch;
    background-color: black;
    color: white;
    height: 800px;
    width: 100%;
}

.contact_section_background {
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.contact_content_container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-self: center;
    align-content: center;
    height: 100%;
    max-height: 400px;
    width: 100%;
    max-width: 1000px;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, .5);
    margin: 10% auto;
}

.contact_headline {
    font-family: 'Abril Fatface';
    font-size: 96px;
    color: white;
}

.contact_paragraph_01 {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    color: white;
    margin: 24px;
    max-width: 800px;
}

.contact_button_space {
    margin: 8px;
}

button {
    color: rgb(63, 8, 8);
    padding: 12px;
    background: rgb(219, 152, 210);
    border-radius: 25px;
    border: 2px solid white;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
}




/* -------------------- LARGE TABLET ------------- */
@media screen and (max-width: 1200px) and (min-width: 992px) {
    .contact_headline {
        font-family: 'Abril Fatface', cursive;
        font-size: 80px;
        color: white;
    }
    
    .contact_paragraph_01 {
        font-family: 'Montserrat', sans-serif;
        font-size: 18px;
        color: white;
        margin: 24px;
    }
}
/* -------------------- SMALL TABLET ------------- */
@media screen and (max-width: 991px) and (min-width: 768px){    
    .contact_headline {
        font-family: 'Abril Fatface', cursive;
        font-size: 64px;
        color: white;
    }
    
    .contact_paragraph_01 {
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        color: white;
        margin: 24px;
    }
}
/* -------------------- LARGE PHONE ------------- */
@media screen and (max-width: 767px) and (min-width: 576px){
    .contact_headline {
        font-family: 'Abril Fatface', cursive;
        font-size: 48px;
        color: white;
    }
    
    .contact_paragraph_01 {
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        color: white;
        margin: 24px;
    }  
}
/* -------------------- SMALL PHONE ------------- */
@media screen and (max-width: 575px) and (min-width: 319px){  
    .contact_content_container {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        align-self: center;
        align-content: center;
        height: 100%;
        max-height: 400px;
        /* width: 100%; */
        max-width: 1000px;
        border-radius: 8px;
        background-color: rgba(0, 0, 0, .5);
        margin: 10%;
    }
    .contact_headline {
        font-family: 'Abril Fatface', cursive;
        font-size: 36px;
        color: white;
    }
    
    .contact_paragraph_01 {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        color: white;
        margin: 24px;
    }
}



body {
    margin: 0;
}

.footer_section {
    height: 200px;
    /* background-color: rgb(4, 36, 46); */
    background-image: -webkit-gradient(linear, left top, right top, from(rgb(26, 102, 165)) , to(rgb(5, 4, 46)));
    background-image: linear-gradient(to right, rgb(26, 102, 165) , rgb(5, 4, 46));
}

.footer_title {
    font-family: 'Kanit', sans-serif;
    font-size: 64px;
    color: white;
    text-align: center;
}

.footer_content {    
    font-family: 'Raleway', sans-serif;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-self: center;
    align-content: center;
    font-size: 14px;
    color: grey;
    text-align: center;
    height: 100%;
    max-height: 100px;
    width: 100%;
    max-width: 300px;
    border-left: 1px solid grey;
    border-right: 1px solid grey;
    margin: 0 auto;
}

.back_to_top {    
    text-align: center;    
    padding-top: 10px;  
}

.back_to_top {
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    color: peru;
    text-decoration: underline;
    padding-top: 24px;
}
