@import url('https://fonts.googleapis.com/css?family=Abril+Fatface&display=swap');
/* font-family: 'Abril Fatface', cursive; */

@import url('https://fonts.googleapis.com/css?family=Montserrat:300&display=swap');
/* font-family: 'Montserrat', sans-serif; */

body {
    margin: 0;
}

.contact_page_section {
    display: flex;
    align-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-self: center;
    align-content: stretch;
    background-color: black;
    color: white;
    height: 800px;
    width: 100%;
}

.contact_section_background {
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.contact_content_container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-self: center;
    align-content: center;
    height: 100%;
    max-height: 400px;
    width: 100%;
    max-width: 1000px;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, .5);
    margin: 10% auto;
}

.contact_headline {
    font-family: 'Abril Fatface';
    font-size: 96px;
    color: white;
}

.contact_paragraph_01 {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    color: white;
    margin: 24px;
    max-width: 800px;
}

.contact_button_space {
    margin: 8px;
}

button {
    color: rgb(63, 8, 8);
    padding: 12px;
    background: rgb(219, 152, 210);
    border-radius: 25px;
    border: 2px solid white;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
}




/* -------------------- LARGE TABLET ------------- */
@media screen and (max-width: 1200px) and (min-width: 992px) {
    .contact_headline {
        font-family: 'Abril Fatface', cursive;
        font-size: 80px;
        color: white;
    }
    
    .contact_paragraph_01 {
        font-family: 'Montserrat', sans-serif;
        font-size: 18px;
        color: white;
        margin: 24px;
    }
}
/* -------------------- SMALL TABLET ------------- */
@media screen and (max-width: 991px) and (min-width: 768px){    
    .contact_headline {
        font-family: 'Abril Fatface', cursive;
        font-size: 64px;
        color: white;
    }
    
    .contact_paragraph_01 {
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        color: white;
        margin: 24px;
    }
}
/* -------------------- LARGE PHONE ------------- */
@media screen and (max-width: 767px) and (min-width: 576px){
    .contact_headline {
        font-family: 'Abril Fatface', cursive;
        font-size: 48px;
        color: white;
    }
    
    .contact_paragraph_01 {
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        color: white;
        margin: 24px;
    }  
}
/* -------------------- SMALL PHONE ------------- */
@media screen and (max-width: 575px) and (min-width: 319px){  
    .contact_content_container {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        align-self: center;
        align-content: center;
        height: 100%;
        max-height: 400px;
        /* width: 100%; */
        max-width: 1000px;
        border-radius: 8px;
        background-color: rgba(0, 0, 0, .5);
        margin: 10%;
    }
    .contact_headline {
        font-family: 'Abril Fatface', cursive;
        font-size: 36px;
        color: white;
    }
    
    .contact_paragraph_01 {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        color: white;
        margin: 24px;
    }
}


