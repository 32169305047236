@import url('https://fonts.googleapis.com/css?family=Abril+Fatface&display=swap');
/* font-family: 'Abril Fatface', cursive; */

@import url('https://fonts.googleapis.com/css?family=Montserrat:300&display=swap');
/* font-family: 'Montserrat', sans-serif; */

body {
    margin: 0;
}

.phil_page_section {
    display: flex;
    align-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-self: center;
    align-content: stretch;
    /* background-image: linear-gradient(to right, rgb(9, 44, 71) , rgb(65, 64, 94)); */
    background-color: white;
    color: white;
    height: 1660px;
    width: 100%;
}

.phil_section_background {
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.phil_content_container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-self: center;
    align-content: center;
    height: 100%;
    max-height: 1400px;
    width: 100%;
    max-width: 1000px;
    /* border-radius: 8px; */
    margin: 10% auto;
    border: white solid 1px;
    background-color: rgb(113, 161, 180);
}

.phil_headline {
    font-family: 'Abril Fatface', cursive;
    font-size: 96px;
    color: white;
}

.phil_paragraph_section {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    color: white;
    margin: 24px;
}

.phil_paragraph_box {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    color: rgb(35, 35, 35);
    margin: 24px;
}

.phil_columns_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    align-content: flex-start;
    margin: 24px 24px 120px;
    max-width: 1200px;
}

.phil_column_box {
    width: 376px;
	background-color: rgb(255, 255, 255);
	border-radius: 9px;
	padding-bottom: 12px;
	margin: 24px;	
}

.phil_box_image {
    width: 100%;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
}

.phil_column_box_title {
    font-family: 'Abril Fatface', cursive;
    font-size: 24px;
    color: peru;
    padding: 24px 24px 12px;
}



/* -------------------- LARGE TABLET ------------- */
@media screen and (max-width: 1200px) and (min-width: 992px) {
    .phil_headline {
        font-family: 'Abril Fatface', cursive;
        font-size: 80px;
        color: white;
    }
    
    .phil_paragraph_section {
        font-family: 'Montserrat', sans-serif;
        font-size: 18px;
        color: white;
        margin: 24px;
    }
}
/* -------------------- SMALL TABLET ------------- */
@media screen and (max-width: 991px) and (min-width: 768px){    
    .phil_headline {
        font-family: 'Abril Fatface', cursive;
        font-size: 64px;
        color: white;
    }
    
    .phil_paragraph_section {
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        color: white;
        margin: 24px;
    }
}
/* -------------------- LARGE PHONE ------------- */
@media screen and (max-width: 767px) and (min-width: 576px){
    .phil_headline {
        font-family: 'Abril Fatface', cursive;
        font-size: 48px;
        color: white;
    }
    
    .phil_paragraph_section {
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        color: white;
        margin: 24px;
    }   
}
/* -------------------- SMALL PHONE ------------- */
@media screen and (max-width: 575px) and (min-width: 319px){  
    .phil_headline {
        font-family: 'Abril Fatface', cursive;
        font-size: 36px;
        color: white;
    }
    
    .phil_paragraph_section {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        color: white;
        margin: 24px;
    }
}


