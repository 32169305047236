@import url('https://fonts.googleapis.com/css?family=Abril+Fatface&display=swap');
/* font-family: 'Abril Fatface', cursive; */

@import url('https://fonts.googleapis.com/css?family=Montserrat:300&display=swap');
/* font-family: 'Montserrat', sans-serif; */

body {
    margin: 0;
}

.youtube1_page_section {
    display: flex;
    align-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-self: center;
    align-content: stretch;
    background-color: rgb(113, 161, 180);
    color: black;
    height: 100%;
    width: 100%;
}

.youtube_section_background {
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    /* padding: 24px */
}

.youtube_content_container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-self: center;
    align-content: center;
    border: 1px white solid;
    border-radius: 8px;
    height: 100%;
    width: 100%;    
    background-color: rgb(113, 161, 180);
    margin: 5% 10%;    
}

.youtube_headline {
    font-family: 'Abril Fatface';
    font-size: 80px;
    color: white;
}

.youtube_player {
    width: 768px;
    height: 432px;
    margin: 20px auto;
}







/* -------------------- LARGE TABLET ------------- */
@media screen and (max-width: 1200px) and (min-width: 992px) {
    .youtube_headline {
        font-family: 'Abril Fatface', cursive;
        font-size: 80px;
        color: white;
    }
    
    .youtube_player {
        width: 800px;
        height: 700px;
    }
}
/* -------------------- SMALL TABLET ------------- */
@media screen and (max-width: 991px) and (min-width: 768px){    
    .youtube_headline {
        font-family: 'Abril Fatface', cursive;
        font-size: 64px;
        color: white;
    }
    
    .youtube_player {
        width: 640px;
        height: 540px;
    }
}
/* -------------------- LARGE PHONE ------------- */
@media screen and (max-width: 767px) and (min-width: 576px){
    .youtube_headline {
        font-family: 'Abril Fatface', cursive;
        font-size: 48px;
        color: white;
    }

    .youtube_player {
        width: 480px;
        height: 380px
    }
}
/* -------------------- SMALL PHONE ------------- */
@media screen and (max-width: 575px) and (min-width: 319px){  
    .youtube_headline {
        font-family: 'Abril Fatface', cursive;
        font-size: 36px;
        color: white;
    }

    .youtube_player {
        width: 320px;
        height: 320px;
    }
}


